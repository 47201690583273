@charset "utf-8";


$base-font-family: 'Varela Round', sans-serif;
$syntax-font-family: 'Roboto Mono', monospace;
$base-font-size:   16px !default;
$post-link-font-size: $base-font-size * 1.25 !default;
$base-font-weight: 500 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;
$syntax-line-height: 1.3 !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

$on-medium:        $on-palm !default;
$on-large: $on-laptop !default;

$spacing-unit: 30px !default;


@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "fa/fontawesome",
  "fa/solid",
  "fa/regular",
  "fa/brands",
  "dash/fonts",
  "dash/icons",
  "dash/colors",
  "dash/themes",
  "dash/mixins",
  "dash/animations",
  "dash/base",
  "dash/layout",
  "dash/dn-toggle",
  "dash/syntax-highlighting"
;
