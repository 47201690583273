@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.eot');
    src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
         url($font-file+'.woff') format('woff'),
         url($font-file+'.ttf') format('truetype'),
         url($font-file+'.svg#aller') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}


@include font('Varela Round', '/assets/fonts/VarelaRound-Regular');
@include font('Roboto Mono', '/assets/fonts/RobotoMono-Medium');