.toggleWrapper {
  display: inline-block;
  float: right;
  position:relative;

  input {
    display: none !important;
  }
}

.toggle {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width:49px;
  height: 28px;
  background-color: #4FC1E4;
  border-radius: 45px - 6;
  transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.toggle__handler {
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 3px;
  left: 3px;
  width: 25px - 3;
  height: 25px - 3;
  background-color: #FFCF96;
  border-radius: 25px;
  box-shadow: 0 2px 6px rgba(0,0,0,.3);
  transition: all 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform:  rotate(-45deg);

  .crater {
    position: absolute;
    background-color: #E8CDA5;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    border-radius: 100%;
  }

  .crater--1 {
    top: 9px;
    left: 5px;
    width: 2px;
    height: 2px;
  }

  .crater--2 {
    top: 14px;
    left: 11px;
    width: 3px;
    height: 3px;
  }

  .crater--3 {
    top: 5px;
    left: 12px;
    width: 4px;
    height: 4px;
  }
}

.star {
  position: absolute;
  background-color: #ffffff;
  transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  border-radius: 50%;
}

.star--1 {
  top: 5px;
  left: 17px;
  z-index: 0;
  width: 15px;
  height: 2px;
}

.star--2 {
  top: 9px;
  left: 14px;
  z-index: 1;
  width: 15px;
  height: 2px;
}

.star--3 {
  top: 14px;
  left: 20px;
  z-index: 0;
  width: 15px;
  height: 2px;
}

.star--4,
.star--5,
.star--6 {
  opacity: 0;
  transition: all 300ms 0 cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.star--4 {
  top: 8px;
  left: 6px;
  z-index: 0;
  width: 2px;
  height: 2px;
  transform: translate3d(3px,0,0);
}

.star--5 {
  top: 16px;
  left: 8px;
  z-index: 0;
  width: 3px;
  height: 3px;
  transform: translate3d(3px,0,0);
}

.star--6 {
  top: 18px;
  left: 14px;
  z-index: 0;
  width: 2px;
  height: 2px;
  transform: translate3d(3px,0,0);
}

input:checked {
  + .toggle {
    background-color: #637AFE;

    &:before {
      color: #637AFE;
    }

    &:after {
      color: #ffffff;
    }

    .toggle__handler {
      background-color: #FFE5B5;
      transform: translate3d(20px, 0, 0) rotate(0);

      .crater { opacity: 1; }
    }

    .star--1 {
      width: 2px;
      height: 2px;
    }

    .star--2 {
      width: 4px;
      height: 4px;
      transform: translate3d(-5px, 0, 0);
    }

    .star--3 {
      width: 2px;
      height: 2px;
      transform: translate3d(-7px, 0, 0);
    }

    .star--4,
    .star--5,
    .star--6 {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
    .star--4 {
      transition: all 300ms 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    .star--5 {
      transition: all 300ms 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    .star--6 {
      transition: all 300ms 400ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
  }
}

